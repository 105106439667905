export const updateUser = ({commit}, user) => {
    commit('userState', user)
}
export const updateToken = ({commit}, token) => {
    commit('setToken', token)
}

export const logout = ({commit}) => {
    commit('userState', null)
    commit('setToken', "")
}